import React, { useState } from "react";

export default function NFTPreview({ imageUrl, animationUrl, isPOAP }) {

  const [showVideo, setShowVideo] = useState(true);

  return (
    <div className="nft-preview">
      {(!showVideo || isPOAP)
        ? <img src={imageUrl}
          className={isPOAP ? "img-fluid": "img-fluid shadow"} />
        : (
          <video width="100%" height="auto" loop autoPlay playsInline muted>
            <source src={animationUrl} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        )
      }
    </div>
  );
}
