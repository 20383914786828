import { CONFIG } from "../config";

export const getNFCLink = async (authToken, customHeader, nfcUid) => {
  return new Promise((resolve) => {
    fetch(`${CONFIG.apiHost}/nfc_link/${nfcUid}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        console.log(response);
        resolve(response);
      });
  });
};

export const getLeaderboard = (authToken, customHeader) => {
  // if (!customHeader) { return; }
  return new Promise((resolve) => {
    fetch(`${CONFIG.cfApiHost}/nfc_link/leaderboard`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        resolve(response);
      });
  });
};
