import { useSelector, useDispatch } from 'react-redux';
import React from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import PromoCode from '../NFTDistributor/PromoCode';
import Import2Metamask from '../ImportWallet/Import2Metamask';
import NFTPreview from '../NFTPreview/NFTPreview';
import {
  selectProducts,
  selectLoadingProducts
} from '../../Reducers/NFTCollectionSlice';
import {
  selectOwnedNfts,
  selectLoadingCollection,
  getOwnedCollection
} from '../../Reducers/OwnedCollectionSlice';
import {
  setCode,
  setCodeStatus
} from '../../Reducers/CodeHandlerSlice';
import {
  setNfcUid,
  setLinked,
  setNftType
} from '../../Reducers/NFTDistributorSlice';
import {
  selectWalletAddress,
  selectAuthToken,
  selectCustomOriginHeader
} from '../../Reducers/WalletManagerSlice';
import { CONFIG } from "../../config";

export default function Collection() {
  const ownedNfts = useSelector(selectOwnedNfts);
  const products = useSelector(selectProducts);
  const loadingProducts = useSelector(selectLoadingProducts);
  const loadingCollection = useSelector(selectLoadingCollection);
  const authToken = useSelector(selectAuthToken);
  const customOriginHeader = useSelector(selectCustomOriginHeader);
  const walletAddress = useSelector(selectWalletAddress);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setCode(''));
    dispatch(setCodeStatus(''));
    dispatch(setNfcUid(''));
    dispatch(setLinked(false));
    dispatch(setNftType('nft'));
  }, []);

  React.useEffect(() => {
    dispatch(getOwnedCollection());
  }, [walletAddress, authToken, customOriginHeader]);

  const collectdata = ownedNfts.map((nft) => {
    const metadata = products[nft.product_id];
    if (metadata) {
      const data = metadata[`${nft.nft_type}_metadata`];
      const contractAddress = nft.nft_type == 'nft' ? CONFIG.premiumNFTContractAddress : CONFIG.patchNFTContractAddress;
      return {
        title_name: data.name,
        item_owned: nft.token.amount,
        img_url: data.image,
        anim_url: data.animation_url,
        description: data.description,
        nft_type: nft.nft_type,
        openSeaLink: `https://opensea.io/assets/matic/${contractAddress}/${nft.token.tokenId}`
      }
    }
  });

  const myCollection = () => {
    return collectdata.map((collect, i) => {
      if (!collect) { return; }
      return (
        <div className="Collection-box" key={i}>
          <div className="collection-box-head text-center">
            <h3>{collect.title_name}</h3>
            <p>You own {collect.item_owned} of these.</p>
          </div>
          <Row>
            <Col xl={6} lg={12} md={12} sm={12}>
              <div className="collection-img-here">
                <NFTPreview
                  imageUrl={collect.img_url}
                  animationUrl={collect.anim_url}
                  isPOAP={collect.nft_type == 'poap'}/>
              </div>
            </Col>
            <Col xl={6} lg={12} md={12} sm={12}>
              <div className="Collection-content">
                <p>Description</p>
                <h4>
                  {collect.description}
                </h4>
                <Button className="opensea"
                  onClick={() => window.open(
                    collect.openSeaLink, '_blank'
                  )}>
                  View on OpenSea
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <section className="createnft-main">
      <Container>
        <div className="inner-wrap">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="createnft-top">
                <div className="create-nft-head collection-head text-center">
                  <h3>Have a code? Claim your NFT</h3>

                  <p>
                    Enter the promotional code that was emailed to you
                  </p>

                  <div className="claim-promo">
                    <PromoCode inCollection={true}/>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {loadingCollection || loadingProducts
            ? <Spinner animation="grow" variant="primary"
              style={{ marginTop: 30 }}/>
            : myCollection()
          }

          <Import2Metamask />
        </div>
      </Container>
    </section>
  );
}
