import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import logo from './logo.svg';
import './App.css';
import Router from "./Routers/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  initWeb3Auth,
  fetchCustomHeaders,
  selectIsNewSession,
  selectAuthToken,
  selectCustomOriginHeader
} from './Reducers/WalletManagerSlice';
import {
  selectNftType,
  selectNfcUid,
  selectLinked,
  determineNftSettings
} from './Reducers/NFTDistributorSlice';
import { fetchLeaderboard } from './Reducers/LeaderboardSlice';
import { getAllMetadata } from './Reducers/NFTCollectionSlice';
import { Debug } from './Components/Debug/Debug';

function App() {
  const nftType = useSelector(selectNftType);
  const nfcUid = useSelector(selectNfcUid);
  const linked = useSelector(selectLinked);
  const isNewSession = useSelector(selectIsNewSession);
  const authToken = useSelector(selectAuthToken);
  const customOriginHeader = useSelector(selectCustomOriginHeader);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchCustomHeaders());
  }, []);

  React.useEffect(() => {
    dispatch(fetchLeaderboard());
  }, [customOriginHeader]);

  React.useEffect(() => {
    dispatch(initWeb3Auth(nftType, isNewSession, linked));
  }, [nftType, isNewSession, linked]);

  React.useEffect(() => {
    if (customOriginHeader) {
      dispatch(getAllMetadata());
      dispatch(determineNftSettings());
    }
  }, [authToken, customOriginHeader, nfcUid]);

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
