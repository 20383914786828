import React from "react";
import { useParams} from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import SignInBanner from "../Components/SignIn/SignInBanner";
import SignIn from "../Components/SignIn/SignIn";
import TopScans from '../Components/Leaderboard/TopScans';
import FAQ from '../Components/FAQ/FAQ';

export default function HomePage(props) {
  return (
    <>
      <Header showSignIn={false} />
      <SignInBanner />
      <SignIn />
      <TopScans />
      <FAQ />
      <Footer />
    </>
  );
}
