import React from "react";
import { Container, Row } from "react-bootstrap";

import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import CollectionBanner from "../Components/OwnedCollection/CollectionBanner";
import Collection from "../Components/OwnedCollection/Collection";
import ClaimedModal from '../Components/NFTDistributor/ClaimedModal';
import TopScans from '../Components/Leaderboard/TopScans';
import DigitalExperiences from '../Components/DigitalExperiences/DigitalExperiences';
import FAQ from '../Components/FAQ/FAQ';

export default function CollectionPage() {
  return (
    <>
      <Header />
      <CollectionBanner />
      <Collection />
      <ClaimedModal />
      <section className="digital-experiences">
        <Container>
          <div className="inner-wrap">
            <Row><DigitalExperiences/></Row>
          </div>
        </Container>
      </section>
      <TopScans />
      <FAQ />
      <Footer />
    </>
  );
}
