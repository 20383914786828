import { CONFIG } from "../config";

export const getCode = async (authToken, customHeader, code) => {
  return new Promise((resolve) => {
    fetch(`${CONFIG.apiHost}/code/${code}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        resolve(response);
      });
  });
};
