import { createSlice } from '@reduxjs/toolkit';
import { getCode } from '../apis/CodeAPI';
import { getNFCLink } from '../apis/NFCLinkAPI';
import { mintNFT } from './NFTDistributorSlice';
import { setErrorMsg } from './ErrorMessageSlice';
import {
  selectWalletAddress,
  selectAuthToken,
  selectCustomOriginHeader
} from './WalletManagerSlice';

const initialState = {
  code: '',
  codeStatus: '',
  mintId: '',
  productId: '',
  validating: false
};

export const claim = (forceRefresh) => async (dispatch, getState) => {
  const walletAddress = selectWalletAddress(getState());
  const code = selectCode(getState());
  const validating = selectValidating(getState());
  const authToken = selectAuthToken(getState());
  const customOriginHeader = selectCustomOriginHeader(getState());

  dispatch(setErrorMsg(''));

  if (!walletAddress && forceRefresh) {
    window.location.replace('/');
    return;
  }


  if (code) {
    dispatch(setValidating(true));
    const response = await getCode(authToken, customOriginHeader, code);
    console.log(response);
    if (response && response.code) {
      dispatch(setCodeStatus(response.status))
      dispatch(setProductId(response.product_id));

      if (walletAddress) {
        dispatch(mintNFT());
      }
    }
    else {
      dispatch(setErrorMsg('Please enter a valid code.'));
      dispatch(setValidating(false));
    }
  }
};

export const validateCode = () => async (dispatch, getState) => {
  const code = selectCode(getState());
  const authToken = selectAuthToken(getState());
  const customOriginHeader = selectCustomOriginHeader(getState());
  if (code) {
    const response = await getCode(authToken, customOriginHeader, code);
    console.log(response);
    if (response && response.code) {
      dispatch(setCodeStatus(response.status))
      dispatch(setProductId(response.product_id));
    }
  }
};

export const isNFCLinked = (nfcUid = '') => {
  return async (dispatch, getState) => {
    const authToken = selectAuthToken(getState());
    const customOriginHeader = selectCustomOriginHeader(getState());

    if (nfcUid) {
      const nfcLink = await getNFCLink(authToken, customOriginHeader, nfcUid);
      if (nfcLink && nfcLink.code) {
        dispatch(setCode(nfcLink.code));
      }
    }
  };
};

export const codeHandlerSlice = createSlice({
  name: 'codeHandler',
  initialState,
  reducers: {
    setCode: (state, action) => { state.code = action.payload; },
    setCodeStatus: (state, action) => { state.codeStatus = action.payload; },
    setProductId: (state, action) => { state.productId = action.payload; },
    setValidating: (state, action) => { state.validating = action.payload; }
  }
});

export const {
  setCode,
  setCodeStatus,
  setProductId,
  setValidating
} = codeHandlerSlice.actions;

export const selectCode = (state) => state.codeHandler.code;
export const selectCodeStatus = (state) => state.codeHandler.codeStatus;
export const selectMintId = (state) => state.codeHandler.mintId;
export const selectProductId = (state) => state.codeHandler.productId;
export const selectValidating = (state) => state.codeHandler.validating;

export default codeHandlerSlice.reducer;
