import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { selectErrorMsg } from '../../Reducers/ErrorMessageSlice';
import {
  claim,
  setCode,
  selectCode,
  selectValidating
} from '../../Reducers/CodeHandlerSlice';


export default function PromoCode({ inCollection = false}) {
  const code = useSelector(selectCode);
  const validating = useSelector(selectValidating);
  const errorMsg = useSelector(selectErrorMsg);
  const dispatch = useDispatch();

  return (
    <>
      <Form onSubmit={(e) => {
          e.preventDefault();
          dispatch(claim(inCollection));
        }}>
        <FormGroup className="promo-code">
          <Input
            type="text"
            name="promo"
            placeholder="Promo Code"
            value={code}
            disabled={validating}
            onChange={
              (e) => dispatch(setCode(e.target.value))
            }
          />
        </FormGroup>
        <Button
          className={validating ? "claim-now validating" : "claim-now"}
          disabled={validating}
          onClick={() => dispatch(claim(inCollection))}>
          { validating
              ? <Spinner animation="border" variant="light" />
              : "Claim Now"
          }
        </Button>
      </Form>
      {errorMsg && <p className="error">{errorMsg}</p>}
    </>
  );
}
