import React, { useState } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";

export default function DigitalExperiences () {
  return (
    <>
      <Col lg={6} md={6} sm={12}>
        <div className="createbotton-box">
          <div className="wrap-img position-relative">
            <img
              src="/images/ReadyPlayerMe-optimized.png"
              alt=""
              className="img-fluid bg-pick"
            />

          </div>

          <h5>Digital Accessories</h5>
          <p>View the Digital Wardrobe Collection</p>
          <a target="_blank" href="https://universalmonsters.readyplayer.me/avatar" className="universal-store digiExperienceLink createAvatar" style={{ marginTop: 40 }}>
            Create Your MONSTERVERSE Avatar
          </a>
        </div>
      </Col>
      <Col lg={6} md={6} sm={12}>
        <div className="createbotton-box snap">
          <img
            src="/images/Ghost Logo (for light backgrounds).png"
            alt=""
            className="img-fluid bg-pick"
          />

          <h5>Snapchat AR Lens</h5>
          <p>Try the Universal Monsters Lens</p>
          <a target="_blank" href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=a2801ad8f8a042b08d63e1fc7e3f2f6d&metadata=01" className="universal-store digiExperienceLink snap">
            Visit Snapchat
          </a>
        </div>
      </Col>
    </>
  );
}
