import React from "react";
import {  Button } from "react-bootstrap";

export default function FAQ() {
  return (
    <section className="FAQ">
      <div className="read-faq">
        <h2>Have questions?</h2>
        <a className="secondaryBtn faqBtn" target="_blank"
          href="https://universalmonsters.store/pages/faq">
          Read the FAQ
        </a>
      </div>
    </section>
  );
}
