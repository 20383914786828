import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  selectWalletAddress,
  logout
} from '../../Reducers/WalletManagerSlice';

export default function Header({ showSignIn = true }) {
  const [nav, setNav] = useState(false);
  const [scroll, setScroll] = useState(false);

  const walletAddress = useSelector(selectWalletAddress);
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <section className={scroll ? "header-main  fixed-header" : "header-main"}>
      <Container>
        <div className="header">
          <Link to={walletAddress ? '/collection' : '/'}
            class="navbar-brand m-auto">
            <img src="/images/logo.png" alt="" className="img-fluid" />
          </Link>

          <div className="add-wallet-wrap">
            {showSignIn &&
              (
                <div className="wallet-section">
                  {
                    walletAddress
                      ? (
                        <Button className="nav-link sigb-upcol signout" onClick={(e) => {
                          dispatch(logout());
                        }}>
                          Sign Out
                        </Button>
                      ) : (
                        <Link to="/" className="nav-link sigb-upcol">
                          Sign In
                        </Link>
                      )
                  }
                </div>
              )
            }
          </div>
        </div>
      </Container>
    </section>
  );
}
