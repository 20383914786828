import { createSlice } from '@reduxjs/toolkit';
import { getLeaderboard } from '../apis/NFCLinkAPI';
import {
  selectAuthToken,
  selectWalletAddress,
  selectCustomOriginHeader
} from './WalletManagerSlice';

const initialState = {
  leaderboard: {},
  loadingLeaderboard: false
};

export const fetchLeaderboard = () => async (dispatch, getState) => {
  const customOriginHeader = selectCustomOriginHeader(getState());
  const authToken = selectAuthToken(getState());
  const stateLeaderboard = selectLeaderboard(getState());

  // if (Object.keys(stateLeaderboard).length > 0) {
  //   return;
  // }

  dispatch(setLoadingLeaderboard(true));
  const leaderboard = await getLeaderboard(authToken, customOriginHeader);
  if (leaderboard) {
    dispatch(setLeaderboard(leaderboard));
    dispatch(setLoadingLeaderboard(false));
  }
};

export const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {
    setLeaderboard: (state, action) => {
      state.leaderboard = action.payload;
    },
    setLoadingLeaderboard: (state, action) => {
      state.loadingLeaderboard = action.payload
    }
  }
});

export const {
  setLeaderboard,
  setLoadingLeaderboard
} = leaderboardSlice.actions;

export const selectLeaderboard = (state) => state.leaderboard.leaderboard;
export const selectLoadingLeaderboard = (state) => state.leaderboard.loadingLeaderboard;

export default leaderboardSlice.reducer;
