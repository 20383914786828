export const CONFIG = {
  apiHost: 'https://l46olig0wi.execute-api.us-east-1.amazonaws.com/prod',
  cfApiHost: 'https://d3ulgygo8kzfbs.cloudfront.net',
  // web3AuthClientId: 'BIaZ0JWrvGWbu76MPG9BAh1upzoZN1dS9rA8pkCZTh7Flhu8VzsNFibJ4EmHj6Y_z-SWV_zWJYEVGH8zO1myG8Y',
  web3AuthClientId: 'BAm2Xl7hGFcQUkbGdAlReX6bCLOwBJ_3t_1nxhrJPsoQ02djMDji-KqarzFA8vRWlBAaeSTo1K6th6aW52rbxCo',
  web3AuthVerifierId: 'name',
  auth0Domain: 'https://dev-d2y72067.us.auth0.com',
  imagesBaseUrl: 'http://localhost:3000/images',
  nftTypePOAP: 'poap',
  nftTypeNFT: 'nft',
  logging: true,
  mintStatusMaxRetries: 30,
  chainConfig: {
    polygon: {
      chainNamespace: "eip155",
      // chainId: "0x13881", // hex of 137, polygon mainnet
      chainId: "0x89", // hex of 137, polygon mainnet
      rpcTarget: "https://polygon-mainnet.g.alchemy.com/v2/y2885x7blHUzaod4VBj2qWWBXbGmH95t"
    }
  },
  web3AuthNetwork: 'mainnet',
  premiumNFTContractAddress: '0xf045dfbecc788698ebebbe72338a194364b0e0db',
  patchNFTContractAddress: '0xeaeea0d06115bb57f3b1005017d0eafa3df00e5a',
  tokenSymbol: 'umstr'
};
