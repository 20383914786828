import { useSelector, useDispatch } from 'react-redux';
import React from "react";
import { Container } from "react-bootstrap";
import { selectWalletAddress } from '../../Reducers/WalletManagerSlice';

export default function CollectionBanner() {
  const walletAddress = useSelector(selectWalletAddress);

  return (
    <section className="home-banner">
      <Container>
        <div className="banner-head">
          <h1>My Collection</h1>
          <p>Wallet Address: {walletAddress}</p>
        </div>
      </Container>
    </section>
  );
}
