import { createSlice } from '@reduxjs/toolkit';
import { getOwnedNFTs } from '../apis/HypermintWrapperAPI';
import { selectProducts } from './NFTCollectionSlice';
import {
  selectWalletAddress,
  selectAuthToken,
  selectCustomOriginHeader
} from './WalletManagerSlice';

const initialState = {
  ownedNfts: [],
  showCollection: false,
  loadingCollection: false
};

export const getOwnedCollection = () => async (dispatch, getState) => {
  const authToken = selectAuthToken(getState());
  const customOriginHeader = selectCustomOriginHeader(getState());
  const walletAddress = selectWalletAddress(getState());
  dispatch(setLoadingCollection(true));
  const collection = await getOwnedNFTs(
    authToken, customOriginHeader, walletAddress);
  dispatch(setOwnedNfts(collection));
  dispatch(setLoadingCollection(false));
};

export const ownedCollectionSlice = createSlice({
  name: 'ownedCollection',
  initialState,
  reducers: {
    setOwnedNfts: (state, action) => { state.ownedNfts = action.payload; },
    setShowCollection: (state, action) => {
      state.showCollection = action.payload;
    },
    setLoadingCollection: (state, action) => {
      state.loadingCollection = action.payload;
    }
  }
});

export const {
  setOwnedNfts,
  setShowCollection,
  setLoadingCollection
} = ownedCollectionSlice.actions;

export const selectOwnedNfts = (state) => state.ownedCollection.ownedNfts;
export const selectShowCollection = (state) => {
  return state.ownedCollection.showCollection;
}
export const selectLoadingCollection = (state) => {
  return state.ownedCollection.loadingCollection;
}

export default ownedCollectionSlice.reducer;
