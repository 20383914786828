import React from "react";
import { Container } from "react-bootstrap";

export default function homebanner() {
  return (
    <section className="home-banner">
      <Container>
        <div className="banner-head">
          <h1>
            Get your <br />
            digital experiences
          </h1>
        </div>
      </Container>
    </section>
  );
}
