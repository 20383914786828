import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import WalletManager from '../WalletManager/WalletManager';
import DigitalExperiences from '../DigitalExperiences/DigitalExperiences';

export default function SignIn() {
  return (
    <section className="createnft-main">
      <Container>
        <div className="inner-wrap">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="createnft-top">
                <div className="create-nft-head text-center">
                  <div>
                    <img
                      src="/images/Patches-Collection-optimized.png"
                      alt=""
                      className="img-fluid"
                    />
                  <h3>Universal Monsters NFT Collection</h3>
                  </div>

                  <div className="signing-promo">
                    <WalletManager />
                  </div>
                </div>
              </div>
            </Col>
            <DigitalExperiences />
          </Row>
        </div>
      </Container>
    </section>
  );
}
