import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCodeStatus } from '../../Reducers/CodeHandlerSlice';
import { login, setIsNewSession } from '../../Reducers/WalletManagerSlice';

import {
  ButtonGroup, Box, Button, Container, Divider, Grid, Paper, Stack, TextField,
  CircularProgress, Alert, Modal, Typography
} from '@mui/material';
import { WALLET_ADAPTERS } from "@web3auth/base";
import styles from './WalletManager.module.css';

export default function WalletManager(props) {
  const codeStatus = useSelector(selectCodeStatus);
  const dispatch = useDispatch();

  return (
    <Container style={{ marginBottom: -20 }}>
      <h5 className={styles.title}>Login to Redeem</h5>
      <Button
        onClick={(e) => {
          if (props.setNewSession) {
            dispatch(setIsNewSession(true));
          }
          dispatch(login(WALLET_ADAPTERS.OPENLOGIN,"google"));
        }}
        className={styles.signinGoogle}>
        <img src="/images/google.png"
          className={styles.signinLogo} alt="google" style={{
            width: '42px', height: '42px', padding: '12px' }}/>
        <p>Continue with Google</p>
      </Button>
      <Button
        onClick={(e)=> {
          if (props.setNewSession) {
            dispatch(setIsNewSession(true));
          }
          dispatch(login(WALLET_ADAPTERS.OPENLOGIN,"apple"));
        }}
        className={styles.signinApple}>
        <img src="/images/Logo - SIWA - Logo-only - Black@3x.png"
          className={styles.signinLogo} alt="apple" style={{
            width: '46px', height: '46px'
          }}/>
        <p>Continue with Apple</p>
      </Button>
    </Container>
  );
}
