import * as React from 'react';
import Table from 'react-bootstrap/Table';

export function Leaderboard(props) {
  const leaderboard = props.leaderboard;

  return (
    <section className="leaderboard">
      <Table responsive>
        <thead>
          <tr>
            <th>Rank</th>
            <th># of Scans</th>
            <th>Wallet Address</th>
          </tr>
        </thead>
        <tbody>
          {leaderboard.map((entry, i) => (
            <tr key={i+1}>
              <td>#{i+1}</td>
              <td>{entry.num_shares}</td>
              <td className="owner">{entry.owner || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </section>
  );
}
