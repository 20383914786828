import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { Row, Col, Button } from "react-bootstrap";
import { Input } from "reactstrap";
import {
  selectPkey,
  exportPrivateKey
} from '../../Reducers/WalletManagerSlice';
import { CONFIG } from '../../config';

export default function Import2Metamask() {
  const [showModal, setShowModal] = useState(false);

  const pkey = useSelector(selectPkey);
  const dispatch = useDispatch();

  const tokenIds = [
    {
      monster: 'Bride of Frankenstein',
      tokenId: 0
    },
    {
      monster: 'Creature from the Black Lagoon',
      tokenId: 1
    },
    {
      monster: 'Dracula',
      tokenId: 2
    },
    {
      monster: "Frankenstein's Monster",
      tokenId: 3
    },
    {
      monster: 'The Mummy',
      tokenId: 4
    },
    {
      monster: 'The Wolf Man',
      tokenId: 5
    }
  ]

  return (
    <section className="import-wallet">
      <div className="topleaders-head">
        <img src="/images/metamask-fox.svg" />
        <h2>
          Bring your collection to Metamask
        </h2>
        <p>Learn how you can import your wallet into Metamask.</p>
      </div>

      <Button className="secondaryBtn import2metamask"
        onClick={() => setShowModal(true)}>
        Show me how
      </Button>

      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="import-wallet-modal"
        dialogClassName="modal-90w"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Body>
          <Button onClick={() => setShowModal(false)}
            className="close-btn">
            <svg width="32" height="32" viewBox="0 0 32 32">
              <path
                fill="#111"
                d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4l6.6 6.6L8 22.6L9.4 24l6.6-6.6l6.6 6.6l1.4-1.4l-6.6-6.6L24 9.4z"
              />
            </svg>
          </Button>
          <h2 className="popcontent text-center">
            Import Wallet to Metamask
          </h2>
          <p className="subtitle">Install the <a href="https://metamask.io/download/" target="_blank">Metamask Mobile App</a> on your phone if you haven't done so already.
          </p>
          <div className="steps">
            <Row>
              <Col xl={3} lg={12} md={12} sm={12} xs={12} className="col">
                <div className="step-header">
                  <h5>Step 1</h5>
                </div>
                <div className="pkey-container">
                  <h6>Get Your Private Key</h6>
                  {pkey
                    ? (
                      <Input
                        type="text"
                        name="pkey"
                        value={pkey}
                        disabled={true} />
                    ) : (<>
                      <Button className="exportPrivateKey" onClick={() => dispatch(exportPrivateKey())}>
                        Reveal Private Key
                      </Button>
                    </>)
                  }
                  <p className="pkey-warning">
                    <br/><strong>CAUTION</strong><br/>
                    <i>
                      Access to your private key gives access to your crypto assets, including NFTs. Do not paste private keys into any phishing or suspicious sites. Do not download any suspicious browser extensions.
                    </i>
                  </p>
                </div>
              </Col>
              <Col xl={2} lg={12} md={12} sm={12} xs={12} className="col">
                <div className="step-header">
                  <h5>Step 2</h5>
                </div>
                <div className="login-metamask">
                  <img src="/images/metamask-instructions-1.jpg"/>
                </div>
                <p>
                  Open Metamask and click on your account profile.
                </p>
              </Col>
              <Col xl={2} lg={12} md={12} sm={12} xs={12} className="col">
                <div className="step-header">
                  <h5 className="step-3">Step 3</h5>
                </div>
                <div className="import-account">
                  <img src="/images/metamask-instructions-2.jpg"/>
                </div>
                <p>
                  Click "Import an Account" and enter your private key.
                </p>
              </Col>
              <Col xl={2} lg={12} md={12} sm={12} xs={12} className="col">
                <div className="step-header">
                  <h5 className="step-4">Step 4</h5>
                </div>
                <div className="setup-network">
                  <h6>
                    Switch to the "NFTs" tab and click on the "Import NFTs" button.
                  </h6>
                  <img src="/images/metamask-instructions-3.jpg"/>
                  <h6>
                    Make sure Polygon mainnet is selected.
                  </h6>
                  <img src="/images/metamask-instructions-4.jpg"/>
                </div>
              </Col>
              <Col xl={3} lg={12} md={12} sm={12} xs={12} className="col">
                <div className="step-header">
                  <h5>Step 5</h5>
                </div>
                <p>Follow <a href="https://metamask.zendesk.com/hc/en-us/articles/360058238591-NFT-tokens-in-your-MetaMask-wallet" target="_blank">these instructions</a> to finish importing your NFTs. Use the below values when prompted.</p>
                <div className="import-nfts">
                  <h6>Import Your NFTs</h6>
                    <h6 className="subtitle1">Premium NFT Contract Address:</h6><br/>
                  <Input
                    type="text"
                    name="premiumNftContract"
                    value={CONFIG.premiumNFTContractAddress}
                    disabled={true} />
                  <h6 className="subtitle1">Patch NFT Contract Address:</h6><br/>
                  <Input
                    type="text"
                    name="patchNftContract"
                    value={CONFIG.patchNFTContractAddress}
                    disabled={true} />
                  <h6 className="subtitle1">Token Symbol:</h6><br/>
                  <Input
                    type="text"
                    name="patchNftContract"
                    value={CONFIG.tokenSymbol}
                    disabled={true} />
                  <Table bordered size="sm">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Universal Monster</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tokenIds.map((entry, i) => (
                        <tr key={i}>
                          <td>{entry.tokenId}</td>
                          <td>{entry.monster}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}
