import React, { Component } from "react";
import { BrowserRouter, Routes, Route, matchPath } from "react-router-dom";

import HomePage from "../Pages/HomePage";
import SignInPage from "../Pages/SignInPage";
import CollectionPage from "../Pages/CollectionPage";


class Router extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<SignInPage />} />
            <Route exact path="/index.html" element={<SignInPage />} />
            <Route exact path="/collection" element={<CollectionPage />} />
            <Route exact path="/:productId"
              element={<HomePage />} />
            <Route exact path="/:productId/:nfcUid"
              element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default Router;
