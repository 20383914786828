import { createSlice } from '@reduxjs/toolkit';
import { CONFIG } from '../config';
import { setShowLoginModal} from './LoginModalSlice';
import {
  selectNftType,
  mintNFT
} from './NFTDistributorSlice';
import { setShowCollection } from './OwnedCollectionSlice';
import {
  ADAPTER_EVENTS,
  SafeEventEmitterProvider,
  WALLET_ADAPTER_TYPE,
  WALLET_ADAPTERS
} from "@web3auth/base";
import { Web3Auth } from "@web3auth/web3auth";
import { Web3AuthCore } from "@web3auth/core";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import Web3 from "web3";

let web3Auth = null;

const initialState = {
  walletAddress: '',
  provider: null,
  authToken: null,
  pkey: '',
  isNewSession: false,
  customOriginHeader: 'OUh5bnp0MjZwM3BVTXU3STRjb3ZQSUVlN3pGVnhJWjU=' // Figure out a better solution eventually...
};

const getCustomOriginHeader = () => {
  const queryParams = getQueryParams();
  console.log("QUERY PARAMS >>", queryParams);
  if (queryParams && queryParams['x-origin-verify']) {
    return queryParams['x-origin-verify'];
  }
};

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

const getAccounts = async (provider) => {
  try {
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    return accounts;
  }
  catch (error) {
    console.error("Error", error);
  }
};

const subscribeAuthEvents = (
  dispatch, web3Auth, nftType, linked, getState) => {

  // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
  web3Auth.on(ADAPTER_EVENTS.CONNECTED, async (data: unknown) => {
    console.log("Yeah!, you are successfully logged in", data);
    console.log(web3Auth.provider);

    const walletAddress = selectWalletAddress(getState());
    const isNewSession = selectIsNewSession(getState());

    if (walletAddress) {
      return;
    }

    const accounts = await getAccounts(web3Auth.provider);
    if (accounts && accounts.length > 0) {
      dispatch(setWalletAddress(accounts[0]));
    }

    dispatch(setProvider(web3Auth.provider));

    // Set up the Authoriztion token for each API request
    const userInfo = await web3Auth.getUserInfo();
    const privateKey = await web3Auth.provider.request({
      method: "eth_private_key",
      params: {},
    });
    const appPubKey = getPublicCompressed(
      Buffer.from(privateKey.padStart(64, "0"), "hex")
    ).toString("hex");
    dispatch(setAuthToken(`${userInfo.idToken}/${appPubKey}`));

    // Determine if we should mint after the user logs in
    console.log("MINTING", isNewSession, nftType, linked);
    if (isNewSession || (nftType == 'poap' && linked)) {
      // mintNFT will determine whether there is an NFT to be minted
      dispatch(mintNFT());
    }
    else {
      if (window.location.pathname == '/') {
        window.location.replace('/collection');
      }
    }

    dispatch(setShowLoginModal(false));
  });

  web3Auth.on(ADAPTER_EVENTS.CONNECTING, () => {
    console.log("connecting");
  });

  web3Auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
    console.log("disconnected");
    dispatch(setWalletAddress(''));
    dispatch(setShowLoginModal(false));
    dispatch(setShowCollection(false));
    web3Auth = null;
    window.location.replace('/');
  });

  web3Auth.on(ADAPTER_EVENTS.ERRORED, (error: unknown) => {
    console.error("some error or user has cancelled login request", error);
  });

};

export const exportPrivateKey = () => async (dispatch, getState) => {
  const provider = selectProvider(getState());
  if (!provider) { return; }
  const privateKey = await provider.request({
    method: "eth_private_key",
    params: {},
  });
  dispatch(setPkey(privateKey));
};

export const initWeb3Auth = (nftType, isNewSession, linked) => {
  return async (dispatch, getState) => {
    console.log(CONFIG.web3AuthNetwork, CONFIG.web3AuthClientId);

    const web3AuthInstance = new Web3AuthCore({
      chainConfig: CONFIG.chainConfig.polygon
      // clientId: CONFIG.web3AuthClientId
    });
    subscribeAuthEvents(dispatch, web3AuthInstance, nftType, linked, getState);

    const adapter = new OpenloginAdapter({
      adapterSettings: {
        network: CONFIG.web3AuthNetwork,
        clientId: CONFIG.web3AuthClientId,
        uxMode: 'popup'
      }
    });
    web3AuthInstance.configureAdapter(adapter);
    await web3AuthInstance.init();
    console.log('web3Auth initialized');
    web3Auth = web3AuthInstance;
  }
};

export const fetchCustomHeaders = () => async (dispatch, getState) => {
  const header = await getCustomOriginHeader();
  if (header) {
    console.log("CUSTOM ORIGIN THING", header);
    dispatch(setCustomOriginheader(header));
    // window.location.replace(window.location.href.split('?')[0]);
  }
};

export const login = (adapterType, loginProvider) => {
  return async (dispatch, getState) => {
    const walletAddress = selectWalletAddress(getState());
    const nftType = selectNftType(getState());
    try {
      await web3Auth.connectTo(adapterType, {
        loginProvider,
        login_hint: ''
      });
      // dispatch(setIsNewSession(true));
    }
    catch (error) {
      console.log("Unable to log in >>>", error);
    }
  }
};

export const logout = () => async (dispatch, getState) => {
  dispatch(setWalletAddress(''));
  dispatch(setShowLoginModal(false));
  dispatch(setShowCollection(false));
  if (web3Auth) {
    await web3Auth.logout();
  }
};

export const walletManagerSlice = createSlice({
  name: 'walletManager',
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
      state.walletAddress = action.payload;
    },
    setConnectTo: (state, action) => { state.connectTo = action.payload; },
    setProvider: (state, action) => { state.provider = action.payload; },
    setAuthToken: (state, action) => { state.authToken = action.payload; },
    setPkey: (state, action) => { state.pkey = action.payload; },
    setIsNewSession: (state, action) => {
      state.isNewSession = action.payload;
    },
    setCustomOriginheader: (state, action) => {
      state.customOriginHeader = action.payload;
    }
  }
});

export const {
  setWalletAddress,
  setProvider,
  setAuthToken,
  setPkey,
  setIsNewSession,
  setCustomOriginheader
} = walletManagerSlice.actions;

export const selectWalletAddress = (state) => state.walletManager.walletAddress;
export const selectProvider = (state) => state.walletManager.provider;
export const selectAuthToken = (state) => state.walletManager.authToken;
export const selectPkey = (state) => state.walletManager.pkey;
export const selectIsNewSession = (state) => state.walletManager.isNewSession;
export const selectCustomOriginHeader = (state) => {
  return state.walletManager.customOriginHeader;
}
export default walletManagerSlice.reducer;
