import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLoginModal: ''
};

export const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    setShowLoginModal: (state, action) => {
      state.showLoginModal = action.payload;
    }
  }
});

export const { setShowLoginModal } = loginModalSlice.actions;

export const selectShowLoginModal = (state) => {
  return state.loginModal.showLoginModal;
};

export default loginModalSlice.reducer;
