import React from "react";
import { useParams} from "react-router-dom";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import HomeBanner from "../Components/HomeBanner/HomeBanner";
import ClaimNFT from '../Components/NFTDistributor/ClaimNFT';
import ClaimedModal from '../Components/NFTDistributor/ClaimedModal';
import TopScans from '../Components/Leaderboard/TopScans';
import FAQ from '../Components/FAQ/FAQ';

export default function HomePage(props) {
  const { productId, nfcUid } = useParams();
  return (
    <>
      <Header />
      <HomeBanner />
      <ClaimNFT productId={productId} nfcUid={nfcUid} />
      <ClaimedModal />
      <TopScans />
      <FAQ />
      <Footer />
    </>
  );
}
