import { configureStore } from '@reduxjs/toolkit';
import codeHandlerReducer from '../Reducers/CodeHandlerSlice';
import walletManagerReducer from '../Reducers/WalletManagerSlice';
import nftDistributorReducer from '../Reducers/NFTDistributorSlice';
import nftCollectionReducer from '../Reducers/NFTCollectionSlice';
import leaderboardReducer from '../Reducers/LeaderboardSlice';
import ownedCollectionReducer from '../Reducers/OwnedCollectionSlice';
import errorMessageReducer from '../Reducers/ErrorMessageSlice';
import loginModalReducer from '../Reducers/LoginModalSlice';

export const store = configureStore({
  reducer: {
    codeHandler: codeHandlerReducer,
    walletManager: walletManagerReducer,
    nftDistributor: nftDistributorReducer,
    nftCollection: nftCollectionReducer,
    leaderboard: leaderboardReducer,
    ownedCollection: ownedCollectionReducer,
    errorMessage: errorMessageReducer,
    loginModal: loginModalReducer
  },
});
