import { CONFIG } from "../config";


export const sendNFT = async (
  authToken, customHeader, code, nfcUid, nftType, walletAddress) => {
  return new Promise((resolve) => {
    if (!authToken) {
      resolve({});
      return;
    }
    fetch(`${CONFIG.apiHost}/nft/monsters/send`, {
      method: 'POST',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      },
      body: JSON.stringify({
        code: code,
        nfc_uid: nfcUid,
        nft_type: nftType,
        wallet_address: walletAddress
      })
    }).then(res => res.json())
      .then(response => {
        resolve(response);
      });
  });
};

export const getOwnedNFTs = async (authToken, customHeader, walletAddress) => {
  return new Promise((resolve) => {
    if (!authToken || !walletAddress) {
      resolve([]);
      return;
    }
    fetch(`${CONFIG.apiHost}/nft/monsters/owner/${walletAddress}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        resolve(response);
      });
  });
};

export const getAllNFTMetadadta = async (authToken, customHeader) => {
  console.log("CUSTOM HEADER >>", customHeader);
  // if (!customHeader) { return; }
  return new Promise((resolve) => {
    fetch(`${CONFIG.cfApiHost}/nft/monsters/metadata/all`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        resolve(response);
      });
  });
};

// Add retry logic
export const getMintStatus = async (
  authToken, customHeader, nftType, mintId
) => {
  console.log("CUSTOM HEADER >>", customHeader);
  // if (!customHeader) { return; }

  return getMintStatusRecursive(
    authToken, customHeader, nftType, mintId, {}, 0
  );
};

const getMintStatusRecursive = async (
  authToken, customHeader, nftType, mintId, currStatus, i
) => {
  // if (!customHeader) { return; }

  if (i >= CONFIG.mintStatusMaxRetries) {
    return currStatus;
  }

  const mintStatus = await getMintStatusHelper(
    authToken, customHeader, nftType, mintId
  );

  console.log("Getting mint status", mintStatus, i);

  if (mintStatus &&
      (mintStatus.status == 'Complete')) {
    return mintStatus;
  }
  else {
   return await wait(() => {
     return getMintStatusRecursive(
       authToken, customHeader, nftType, mintId, mintStatus, ++i
     );
   }, 1500);
  }
};

const wait = async (func, ms) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const result = await func();
      resolve (result)
    }, ms);
  });
}

const getMintStatusHelper = async (
  authToken, customHeader, nftType, mintId
) => {
  // if (!customHeader) { return; }\
  return new Promise((resolve) => {
    fetch(`${CONFIG.apiHost}/nft/monsters/nft/${nftType}/${mintId}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        'x-origin-verify': customHeader
      }
    })
      .then(res => res.json())
      .then(async (response) => {
        resolve(response);
      });
  });
};
