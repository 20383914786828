import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMsg: ''
};

export const errorMessageSlice = createSlice({
  name: 'errorMessage',
  initialState,
  reducers: {
    setErrorMsg: (state, action) => { state.errorMsg = action.payload; }
  }
});

export const { setErrorMsg } = errorMessageSlice.actions;

export const selectErrorMsg = (state) => state.errorMessage.errorMsg;

export default errorMessageSlice.reducer;
