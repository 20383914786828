import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import WalletManager from '../WalletManager/WalletManager';
import PromoCode from './PromoCode';
import { setProductId } from '../../Reducers/CodeHandlerSlice';
import { selectErrorMsg } from '../../Reducers/ErrorMessageSlice';
import DigitalExperiences from '../DigitalExperiences/DigitalExperiences';
import {
  selectWalletAddress,
  setIsNewSession
} from '../../Reducers/WalletManagerSlice';
import {
  setNfcUid,
  selectNftType,
  selectLoadingNFT
} from '../../Reducers/NFTDistributorSlice';
import {
  selectProducts,
  selectLoadingProducts
} from '../../Reducers/NFTCollectionSlice';
import {
  claim,
  setCode,
  selectCode,
  selectCodeStatus,
  selectValidating
} from '../../Reducers/CodeHandlerSlice';


export default function CreateNFt(props) {
  const { productId, nfcUid } = props;
  const [showLogin, setShowLogin] = useState(false);

  const walletAddress = useSelector(selectWalletAddress);
  const code = useSelector(selectCode);
  const codeStatus = useSelector(selectCodeStatus);
  const validating = useSelector(selectValidating);
  const loadingNFT = useSelector(selectLoadingNFT);
  const products = useSelector(selectProducts);
  const loadingProducts = useSelector(selectLoadingProducts);
  const nftType = useSelector(selectNftType);
  const errorMsg = useSelector(selectErrorMsg);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (productId) {
      dispatch(setProductId(productId));
    }
    if (nfcUid) {
      dispatch(setNfcUid(nfcUid));
    }
  }, []);

  let metadata = {};
  if (productId && products[productId]) {
    if (nftType == 'nft') {
      metadata = products[productId].nft_metadata;
    }
    else if (nftType == 'poap') {
      metadata = products[productId].poap_metadata;
    }
  }

  const containerAction = () => {
    if (loadingNFT) {
      return <div className="claim-promo">
        <Spinner animation="grow" variant="primary"  />
      </div>;
    }
    else if (
      (codeStatus == 'ACTIVE' || codeStatus == 'REDEEMED' || nftType == 'poap') && !errorMsg) {
      return <div className="claim-promo">
        <WalletManager setNewSession={true} />
      </div>
    }
    else {
      return <div className="claim-promo">
        <p className="enter-p">
          Enter the promotional code that was emailed to you
        </p>
        <PromoCode />
      </div>;
    }
  };

  const container = (
    <div className="createnft-top">
      <div className="create-nft-head text-center">
        <h3>{metadata.name}</h3>
        <p>Add the NFT to your collection</p>
        <img
          src={metadata.image || ''}
          alt=""
          className={
            nftType == 'nft'
              ? "img-fluid home-thsirt shadow"
              : "img-fluid home-thsirt"
          }
        />
        {containerAction()}
        <p className="universal-store-claim">
          Visit{" "}
          <a href="https://universalmonsters.store"
            target="_blank">
            UniversalMonsters.store
          </a>
        </p>
      </div>
    </div>
  );

  return (
    <>
      <section className="createnft-main">
        <Container>
          <div className="inner-wrap">
            <Row>
              <Col lg={12} md={12} sm={12}>
                {loadingProducts || !metadata
                  ? <Spinner animation="grow" variant="primary"  />
                  : container
                }
              </Col>
              <DigitalExperiences />
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}
