import { createSlice } from '@reduxjs/toolkit';
import { getAllNFTMetadadta } from '../apis/HypermintWrapperAPI';
import {
  selectAuthToken,
  selectCustomOriginHeader
} from './WalletManagerSlice';

const initialState = {
  products: {},
  loadingProducts: false
};

export const getAllMetadata = () => {
  return async (dispatch, getState) => {
    const products = selectProducts(getState());
    const authToken = selectAuthToken(getState());
    const customOriginHeader = selectCustomOriginHeader(getState());

    if (!products || Object.keys(products).length > 0) {
      return;
    }
    dispatch(setLoadingProducts(true));
    const allMetadata = await getAllNFTMetadadta(authToken, customOriginHeader);
    console.log(allMetadata);
    dispatch(setProducts(allMetadata));
    dispatch(setLoadingProducts(false));
  };
};

export const nftCollectionSlice = createSlice({
  name: 'nftCollection',
  initialState,
  reducers: {
    setProducts: (state, action) => { state.products = action.payload; },
    setLoadingProducts: (state, action) => {
      state.loadingProducts = action.payload;
    }
  }
});

export const {
  setProducts,
  setLoadingProducts
} = nftCollectionSlice.actions;

export const selectProducts = (state) => state.nftCollection.products;
export const selectLoadingProducts = (state) => {
  return state.nftCollection.loadingProducts;
}

export default nftCollectionSlice.reducer;
