import { createSlice } from '@reduxjs/toolkit';
import { redeemCode } from '../apis/CodeAPI';
import {
  getNFCLink
} from '../apis/NFCLinkAPI';
import {
  validateCode,
  setCode,
  setCodeStatus,
  setProductId,
  selectCode,
  selectCodeStatus,
  selectProductId,
  setValidating
} from './CodeHandlerSlice';
import {
  selectWalletAddress,
  selectAuthToken,
  selectCustomOriginHeader,
  setIsNewSession
} from './WalletManagerSlice';
import {
  selectErrorMsg,
  setErrorMsg
} from './ErrorMessageSlice';
import {
  sendNFT,
  getMintStatus
} from '../apis/HypermintWrapperAPI';
import { fetchLeaderboard } from './LeaderboardSlice';

const initialState = {
  mintedNft: null,
  mintErrorMsg: '',
  nftType: '',
  nfcUid: '',
  linked: false,
  loadingNFT: false
};

const getNFCUid = () => {
  const pathParms = window.location.pathname;
  if (pathParms.includes('/')) {
    const parts = pathParms.split('/');
    if (parts.length >= 3) {
      return parts[2].split('?')[0];
    }
  }
};

const getProductId = () => {
  const pathParms = window.location.pathname;
  if (pathParms.includes('/')) {
    const parts = pathParms.split('/');
    if (parts.length >= 2) {
      return parts[1];
    }
  }
};

export const determineNftSettings = () => async (dispatch, getState) => {
  const authToken = selectAuthToken(getState());
  const customOriginHeader = selectCustomOriginHeader(getState());
  const nfcUid = selectNfcUid(getState());
  const code = selectCode(getState());

  // If the NFC UID has already been saved in the state,
  // we can assume  the setup has already been done
  if (nfcUid && code) { return; }

  if (!nfcUid) {
    dispatch(setNftType('nft'));
  }
  else {
    dispatch(setLoadingNFT(true));
    console.log('loading');
    const nfcLink = await getNFCLink(authToken, customOriginHeader, nfcUid);
    if (nfcLink && nfcLink.code) {
      dispatch(setLinked(true));
      dispatch(setCode(nfcLink.code));
      dispatch(validateCode());
      dispatch(setNftType('poap'));
    }
    else {
      dispatch(setNftType('nft'));
    }
    dispatch(setLoadingNFT(false));
  }
};

export const checkMintStatus = (nftType, mintId) => {
  return async (dispatch, getState) => {
    const authToken = selectAuthToken(getState());
    const customOriginHeader = selectCustomOriginHeader(getState());
    const mintStatus = await getMintStatus (
      authToken, customOriginHeader, nftType, mintId
    );

    if (mintStatus.status == 'Complete') {
      dispatch(setMintedNft(mintId));
      dispatch(fetchLeaderboard());
    }
    else if (mintStatus.status == 'Pending' || mintStatus.status == 'Sent') {
      dispatch(setErrorMsg("Your NFT is on the way! Please check in later to see it in your collection."));
    }
    else {
      dispatch(setErrorMsg('Unable to Mint NFT. Please try again.'));
    }
    dispatch(setValidating(false));
    dispatch(setLoadingNFT(false));
  };
};

export const mintNFT = () => {
  return async (dispatch, getState) => {
    let nfcUid = selectNfcUid(getState());
    let codeStatus = selectCodeStatus(getState());
    let linked = selectLinked(getState());
    let nftType = selectNftType(getState());
    let mintedNFT = selectMintedNFT(getState());
    let mintErrorMsg = selectMintErrorMsg(getState());
    let errorMsg = selectErrorMsg(getState());
    const productId = selectProductId(getState());
    const authToken = selectAuthToken(getState());
    const walletAddress = selectWalletAddress(getState());
    const code = selectCode(getState());
    const customOriginHeader = selectCustomOriginHeader(getState());

    console.log("MINTING");

    dispatch(setLoadingNFT(true));
    // Make sure the NFC UID matches the code value when minted
    if (nfcUid) {
      const nfcLink = await getNFCLink(authToken, customOriginHeader, nfcUid);
      if (nfcLink && nfcLink.code) {
        if (nfcLink.code != code) {
          nfcUid = '';
          linked = false;
          nftType = 'nft';
        }
      }
    }

    const mintResult = await sendNFT(
      authToken, customOriginHeader, code, nfcUid, nftType, walletAddress
    );

    console.log(mintResult);

    if (mintResult && mintResult.id) {
      // Redeem the code
      mintedNFT = mintResult.id;
      codeStatus = 'REDEEMED';
      nftType = mintResult.nftType;
      if (nfcUid) { linked = true; }
    }
    else if (mintResult.errorMsg) {
      errorMsg = mintResult.errorMsg;
      mintErrorMsg = mintResult.errorMsg;
    }

    console.log(mintErrorMsg);

    // Update the UI with the new state vars
    // dispatch(setLoadingNFT(false));
    dispatch(setMintErrorMsg(mintErrorMsg));
    dispatch(setErrorMsg(errorMsg));
    // dispatch(setNfcUid(nfcUid));
    // dispatch(setCodeStatus(codeStatus));
    // dispatch(setLinked(linked));
    // dispatch(setMintedNft(mintedNFT));
    if (mintResult && mintResult.id) {
      dispatch(checkMintStatus(nftType, mintedNFT));
    }
    else {
      dispatch(setValidating(false));
      dispatch(setLoadingNFT(false));
    }
    dispatch(setNftType(nftType));
    dispatch(setIsNewSession(false));
  };
};

export const nftDistributorSlice = createSlice({
  name: 'nftDistributor',
  initialState,
  reducers: {
    setMintedNft: (state, action) => { state.mintedNFT = action.payload; },
    setMintErrorMsg: (state, action) => { state.mintErrorMsg = action.payload },
    setNftType: (state, action) => { state.nftType = action.payload; },
    setNfcUid: (state, action) => { state.nfcUid = action.payload; },
    setLinked: (state, action) => { state.linked = action.payload; },
    setLoadingNFT: (state, action) => { state.loadingNFT = action.payload; }
  }
});

export const {
  setMintedNft,
  setMintErrorMsg,
  setNftType,
  setNfcUid,
  setLinked,
  setLoadingNFT
} = nftDistributorSlice.actions;

export const selectMintedNFT = (state) => state.nftDistributor.mintedNFT;
export const selectMintErrorMsg = (state) => state.nftDistributor.mintErrorMsg;
export const selectNftType = (state) => state.nftDistributor.nftType;
export const selectNfcUid = (state) => state.nftDistributor.nfcUid;
export const selectLinked = (state) => state.nftDistributor.linked;
export const selectLoadingNFT = (state) => state.nftDistributor.loadingNFT;

export default nftDistributorSlice.reducer;
