import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { Leaderboard } from './Leaderboard';
import {
  selectLeaderboard,
  selectLoadingLeaderboard
} from '../../Reducers/LeaderboardSlice';
import {
  selectProducts,
  selectLoadingProducts
} from '../../Reducers/NFTCollectionSlice';

export default function TopScans() {
  const [showTopLeaders, setShowTopLeaders] = useState(false);

  const loadingLeaderboard = useSelector(selectLoadingLeaderboard);
  const leaderboard = useSelector(selectLeaderboard);
  const loadingProducts = useSelector(selectLoadingProducts);
  const products = useSelector(selectProducts);
  const dispatch = useDispatch();

  console.log("Leaderboard >");
  console.log(leaderboard);

  const topLeaders = (
    <Container>
      <div className="inner-wraptop">
        <Row className="align-items-top">
          {(() => {
            if (!leaderboard) { return <p>Loading...</p>}
            const productIds = Object.keys(leaderboard);

            return productIds.map((pId, i) => {
              const product = products[pId];
              if (!product) { return; }
              const leaders = leaderboard[pId];
              let topLeader = {};
              if (leaders.length > 0) {
                topLeader = leaders[0];
              }
              return (
                <Col lg={6} md={6} sm={12} key={pId}>
                  <div className={showTopLeaders
                      ? "topleader-wrap-open" : "topleader-wrap"}>
                    <div className="top-leaderleft">
                      <img src={product.poap_metadata.image}
                        alt="" className="img-fluid" />
                    </div>
                    <div className="top-leaderright">
                      <p className="top-leader-badge">Top Leader</p>
                      <p className="code-top">
                        {topLeader.owner || "N/A"}
                      </p>
                      <p className="shares-topleader" >
                        {topLeader.num_shares || 0} {topLeader.num_shares == 1 ? "share" : "shares" }
                      </p>
                      {showTopLeaders
                        ? (<Button className="view-leaders" variant="outline-primary" onClick={() => setShowTopLeaders(false)}>
                          View less
                        </Button>)
                        : (<Button className="view-leaders" variant="outline-primary" onClick={() => setShowTopLeaders(true)}>
                          View top leaders
                        </Button>)
                      }
                    </div>
                  </div>
                  {showTopLeaders
                    && <Leaderboard leaderboard={leaders} />}
                </Col>
              );
            })
          })()
        }
        </Row>
      </div>
    </Container>
  );

  return (
    <section className="top-leader">
      <div className="topleaders-head">
        <h2>
          Scan your <br />Monster Patch
        </h2>
        <p>
          You and your friends can use your smartphone to scan the embroidered
          Monster Patch on your garment to redeem a free NFT. The Patches with
          the most scans appear below.
        </p>
      </div>
      {loadingLeaderboard || loadingProducts
        ? <Spinner animation="grow" variant="primary" />
        : topLeaders
      }
    </section>
  );
}
