import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NFTPreview from '../NFTPreview/NFTPreview';
import { selectProductId } from '../../Reducers/CodeHandlerSlice';
import { getOwnedCollection } from '../../Reducers/OwnedCollectionSlice';
import { setErrorMsg } from '../../Reducers/ErrorMessageSlice';
import { fetchLeaderboard } from '../../Reducers/LeaderboardSlice';
import {
  selectProducts
} from '../../Reducers/NFTCollectionSlice';
import {
  selectMintedNFT,
  selectMintErrorMsg,
  selectNftType,
  setMintedNft,
  setMintErrorMsg
} from '../../Reducers/NFTDistributorSlice';

export default function ClaimedModal(props) {
  const navigate = useNavigate();

  const products = useSelector(selectProducts);
  const productId = useSelector(selectProductId);
  const nftType = useSelector(selectNftType);
  const mintedNFT = useSelector(selectMintedNFT);
  const mintErrorMsg = useSelector(selectMintErrorMsg);
  const dispatch = useDispatch();

  console.log("MINTED NFT >>", mintedNFT);

  let metadata = {};
  if (productId && products[productId]) {
    if (nftType == 'nft') {
      metadata = products[productId].nft_metadata;
    }
    else if (nftType == 'poap') {
      metadata = products[productId].poap_metadata;
    }
  }

  if (!mintedNFT && !mintErrorMsg) {
    return <></>;
  }
  else {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="capturemodal"
        show={mintedNFT != null || mintErrorMsg != ''}
      >
        <Modal.Body>
          <div className="popcontent text-center">
            {mintedNFT
              ? <h4>You just got a {metadata.name}!</h4>
              : <>
                <h4>Uh oh!</h4>
                <p>{mintErrorMsg}</p>
              </>
            }
            {!mintErrorMsg && (
              <div className="images-captured text-center">
                <NFTPreview
                  imageUrl={metadata.image}
                  animationUrl={metadata.animation_url}
                  isPOAP={nftType == 'poap'} />
              </div>
            )}
            <Button
              className="see-collection"
              onClick={() => {
                dispatch(setMintedNft(null));
                dispatch(setMintErrorMsg(''));
                dispatch(getOwnedCollection());
                dispatch(fetchLeaderboard());
                dispatch(setErrorMsg(''));
                navigate("/collection");
              }}>
              See My Collection
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
