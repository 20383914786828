import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";

export default function Footer() {
  return (
    <section className="footer-main">
      <Container>
        <div className="copyright text-center">
          <p>© 2022 Variant</p>
          <a target="_blank" href="https://universalmonsters.store/policies/privacy-policy">
            Privacy Policy
          </a>
          <a target="_blank" href="https://universalmonsters.store/policies/terms-of-service">
            Terms of Service
          </a>
          <a target="_blank" href="https://universalmonsters.store/pages/ccpa-opt-out">
            Do not sell my personal information
          </a>
        </div>
      </Container>
    </section>
  );
}
